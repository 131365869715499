import "@assets/css/components/FeedbackAsesorias/style.css";
import TitleDashboard from '@components/general/Titles/TitleDashboard'
import { faBook, faBookOpen, faBoxOpen, faCalendar, faClock, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import getDateFromUnix from "@helpers/dates/getDateFromUnix/getDateFromUnix";
import { getUltimaAgenda, updateAgendaAsesoriaFeedback } from '@helpers/httpRequests/httpRequestAgendaAsesorias/httpRequestAgendaAsesorias';
import { Typography } from '@mui/material'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Animated } from 'react-animated-css';
import { Button, Card, Col, Container, FloatingLabel, Form, Image, Row } from 'react-bootstrap'
import { NotificationManager } from 'react-notifications';
import ReactStars from "react-rating-stars-component";
import Particles from 'react-tsparticles';
import { loadFull } from 'tsparticles';


const FeedbackAsesorias = () => {

  const formButtonRefFeedback = useRef(null);

  const [showLoader, setShowLoader] = useState(false);

  const [ultimaAsesoria, setUltimaAsesoria] = useState(null);
  
  const [ultimaAgenda, setUltimaAgenda] = useState({});

  const [refetch, setRefetch] = useState(false);
  
  const [showDialogFeedback, setShowDialogFeedback] = useState(false);

  const [showBtn, setShowBtn] = useState(false);

  const [formValues, setFormValues] = useState({
    fechaAgenda: 0,
    horarioAgenda: "",
    temaElegido: "Elige un tema",
    problematica: "",
    feedback: "",
    calificacion: 0,

    idAgendaAsesoria: 0,
  })

  const particlesOptions = {
    fpsLimit: 60,
    particles: {
      number: {
        value: 100,
        density: {
          enable: true,
        },
      },
      color: {
        value: ["#ffcc00", "#757676", "#ff9900", "#00152A", "#f07f13"],
      },
      opacity: {
        value: { min: 0.1, max: 0.5 },
      },
      size: {
        value: { min: 1, max: 3 },
      },
      move: {
        enable: true,
        speed: 6,
        random: false,
      },
    },
    interactivity: {
      detectsOn: "window",
      events: {
        onClick: {
          enable: true,
          mode: "push",
        },
        resize: true,
      },
    },
    background: {
      image: "radial-gradient(#01488f, rgba(0, 21, 42, 1))",
    },
  };

  const [agendaContestada, setAgendaContestada]= useState(false)

  const handleOnChange = ({ target }) => {
    const { name, value } = target;

    setFormValues({
      ...formValues,
      [name]:
        name === "feedback"
          ? value.replace(
            /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}\u{1F900}-\u{1F9FF}\u{1F1E0}-\u{1F1FF}\u{1F191}-\u{1F251}\u{1F600}-\u{1F64F}\u{1F910}-\u{1F91F}\u{1F920}-\u{1F927}\u{1F930}-\u{1F930}\u{1F933}-\u{1F93E}\u{1F9D0}-\u{1F9FF}\u{1F600}-\u{1F64F}\u{1F680}-\u{1F6FF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}\u{1F900}-\u{1F9FF}\u{1F600}-\u{1F64F}\u{1F680}-\u{1F6FF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}\u{1F900}-\u{1F9FF}]/gu,
            ""
          )
          : value,
    });
  };

  const ratingChanged = (newRating) => {
    setFormValues({
      ...formValues,
      calificacion: newRating,
    });
  };

  const consultarUltimaAgenda = async (idPerfil) => {
      try {
        const ultimaAgenda = await getUltimaAgenda(idPerfil);

  
        setUltimaAgenda(ultimaAgenda);
  
        setUltimaAsesoria(ultimaAgenda);

        if( ultimaAgenda.feedback !== null){
          setAgendaContestada(true);
        }
      } catch (error) {
        console.log("Error al consultar la ultima agenda: ", error);
  
        // NotificationManager.error("No se pudo consultar la última agenda resuelta");
      }
    };

  const handleCloseDialogFeedback = () => {
    setShowDialogFeedback(false);
  };

  const handleFeedback = async (e) => {
      e.preventDefault();
  
      try {
        setShowLoader(true);
  
        await updateAgendaAsesoriaFeedback({
          idAgendaAsesoria: formValues.idAgendaAsesoria,
          feedback: formValues.feedback,
          calificacion: formValues.calificacion,
        });
  
        NotificationManager.success("Retroalimentación enviada satisfactoriamente");
  
        setShowLoader(false);

        setAgendaContestada(true);
  
        setRefetch(true);
  
        handleCloseDialogFeedback();
      } catch (error) {
        console.log("error al actualizar el feedback: ", error);
      }
    };

    const recuperarDataAsesoria = () => {
      //Se reciben los datos para manejarlos en la vista pública
      const qsParse = new URLSearchParams(window.location.search);

      const datosURL = decodeURIComponent(window.atob(qsParse.get("data")));
      //Transformamos el string del data en arreglo
      const array = JSON.parse(datosURL);

      //Desestructuramos los datos y los pasamos al certificado

      const {
       fechaSolicitud,
       fechaAgenda,
       horarioAgenda,
       problematica,
       temaElegido,
       idPerfil,
       nombreCompleto,
       apellidos,
       correo,
       idAgendaAsesoria
      } = array;

      setFormValues({
        ...formValues,
        fechaSolicitud,
        fechaAgenda,
        horarioAgenda,
        temaElegido,
        idPerfil,
        problematica,
        correo,
        nombreCompleto,
        apellidos,
        idAgendaAsesoria: parseInt(idAgendaAsesoria),
      })


        consultarUltimaAgenda(idPerfil);
      
    }

    useEffect(() => {
      recuperarDataAsesoria();
      
    }, [])
    

  const particlesInit = useCallback(async (engine) => {
    await loadFull(engine);
  }, []);

  useEffect(() => {
    if(formValues.calificacion !== 0){
      setShowBtn(true)
    }
  }, [ratingChanged])
  

  return (
    <>
      <Particles options={particlesOptions} init={particlesInit} id="tsparticles" />

  
          <Container className="mb-5" fluid > 
            <Card className="w-100 contenedor-feedback pb-5" style={{background: "none", border: "none"}}>
            {agendaContestada ? (
              <Row className='justify-content-center d-flex flex-column align-items-center mt-5'>
                <Col className="col-auto shadow-lg p-4 text-center" style={{background: '#fff', borderRadius: 10}}>
                    <Image src={require("@assets/images/check.png")} width="75"/>
                    <h2>Gracias por tu opinión</h2>
                    <h4>Ya has contestado esta encuesta.</h4>
                    <Row className="mt-4 justify-content-center">
                      <Col className="col-8">
                        <h6>Continúa con tu aprendizaje mientras nosotros mejoramos con tu ayuda para brindarte un mejor servicio.</h6>
                        <div className="gap-2 d-grid mt-4">
                          <a className="btn btn-success" href={`${window.location.origin}`}>Iniciar sesión</a>
                        </div>
                      </Col>
                    </Row>
                </Col>
              </Row>
            ): (
              <Row className='justify-content-center d-flex flex-column align-items-center mt-5'>
                <Col className="col-12 col-sm-10 col-md-8 shadow-lg p-4" style={{background: '#fff', borderRadius: 10}}>
                  <Col className='col-12 mb-3'>
                    <Col className="text-center">
                      <Image src={require("@assets/images/click.png")} width={250} />
                    </Col>
                    <TitleDashboard title={"TU ASESORÍA PERSONALIZADA"} />
                    <p className="mt-3 mb-4"><b>Nos encantaría saber que tal estuvo tu asesoría personalizada: </b></p>
                  </Col>

                  <Col className="col-12">
                    <p><FontAwesomeIcon style={{marginRight: 10}} icon={faBookOpen} color="gray"/> Tema elegido: <b>{formValues.temaElegido}</b></p>
                    <p><FontAwesomeIcon style={{marginRight: 10}} icon={faCalendar} color="gray"/> Fecha agendada: <b>{formValues.fechaAgenda}</b></p>
                    <p><FontAwesomeIcon style={{marginRight: 10}} icon={faClock} color="gray"/> Horario de la agenda: <b>{formValues.horarioAgenda}</b></p>
                    <p><FontAwesomeIcon style={{marginRight: 10}} icon={faBook} color="gray"/> Problemática a resolver: <b>{formValues.problematica}</b></p>
                  </Col>

                  <Col className="col-12">
                    <Form className="mt-3" onSubmit={handleFeedback} >
                      <Form.Group className="mb-3 shadow">
                        <FloatingLabel label="Escribe tu experiencia con esta asesoría">
                          <Form.Control name="feedback" value={formValues.feedback} as="textarea" placeholder="Escribe tu experiencia con esta asesoría" style={{ height: "150px", background: '#f2f2f2' }} onChange={handleOnChange} />
                        </FloatingLabel>
                      </Form.Group>

                      <Button hidden type="submit" ref={formButtonRefFeedback} />
                    </Form>


                    <Row className='justify-content-center'>
                      <Col className="col-10 text-center">
                        <Typography style={{ fontSize: "1.6rem" }} gutterBottom variant="overline">
                          Califica tu experiencia
                        </Typography>

                      </Col>
                      <Col className="col-auto">
                        <ReactStars count={5} onChange={ratingChanged} size={65} activeColor="#ff9900" />
                      </Col>
                    </Row>
                    {showBtn ? (
                      <Row className="mt-3">
                          <Col className="gap-2 d-grid">
                            <Button disabled={showLoader} variant="success" onClick={() => formButtonRefFeedback?.current.click()}>
                              Enviar retroalimentación {showLoader ? <FontAwesomeIcon icon={faSpinner} spin /> : null}
                            </Button>
                          </Col>
                        </Row>
                    ): null}

                  </Col>
                </Col>
              </Row>
            )}
            

            

            </Card>
          </Container>

        

    </>
  )
}

export default FeedbackAsesorias