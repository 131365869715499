import endpointsPaths from "@helpers/httpRequests/endpointsPaths/endpointsPaths";
import httpRequest from "@helpers/httpRequests/httpRequest";
import httpRequestsPaths from "@helpers/httpRequestsPaths/httpRequestsPaths";

export const addUsuarioTitular = (datosUsuario) => {
  return new Promise(async (resolve, reject) => {
    try {
      const usuarioRegistrado = await httpRequest({
        url: `${endpointsPaths.usuarios}/${httpRequestsPaths.usuarios.registro.addUsuarioTitular}`,
        method: "POST",
        body: JSON.stringify(datosUsuario),
      });

      resolve(usuarioRegistrado);
    } catch (error) {
      // console.log("Error al agregar al usuario titular proporcionado: ", error);

      reject(error);
    }
  });
};

export const addAdministrador = (administrador) => {
  return new Promise(async (resolve, reject) => {
    try {
      const administradorAgregado = await httpRequest({
        url: `${endpointsPaths.usuarios}/${httpRequestsPaths.usuarios.administrador.addAdministrador}`,
        method: "POST",
        body: JSON.stringify(administrador),
      });

      resolve(administradorAgregado);
    } catch (error) {
      // console.log("Error al agregar al administrador: ", error);

      reject(error);
    }
  });
};

export const addUsuarioEmpresarial = (usuarioEmpresarial) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data: usuarioRegistrado } = await httpRequest({
        url: `${endpointsPaths.usuarios}/${httpRequestsPaths.usuarios.registro.addEmpleado}`,
        method: "POST",
        body: JSON.stringify(usuarioEmpresarial),
      });
      console.log(usuarioRegistrado);
      resolve(usuarioRegistrado);
    } catch (error) {
      // console.log("Error al agregar el usuario empresarial: ", error);

      reject(error);
    }
  });
};

export const addUsuarioInvitado = (datosUsuario) => {
  return new Promise(async (resolve, reject) => {
    try {
      const usuarioAgregado = await httpRequest({
        url: `${endpointsPaths.usuarios}/${httpRequestsPaths.usuarios.registro.addUsuarioInvitado}`,
        method: "POST",
        body: JSON.stringify(datosUsuario),
      });

      resolve(usuarioAgregado);
    } catch (error) {
      // console.log("Error al agregar al usuario invitado: ", error);

      reject(error);
    }
  });
};

export const addUsuarioLecturaVeloz = (datosUsuario) => {
  return new Promise(async (resolve, reject) => {
    try {
      const usuarioAgregado = await httpRequest({
        url: `${endpointsPaths.usuarios}/${httpRequestsPaths.usuarios.registro.addUsuarioLecturaVeloz}`,
        method: "POST",
        body: JSON.stringify(datosUsuario),
      });

      resolve(usuarioAgregado);
    } catch (error) {
      // console.log("Error al registrar al usuario de lectura veloz: ", error);

      reject(error);
    }
  });
};

export const addUsuarioCortesia = (datosUsuario) => {
  return new Promise(async (resolve, reject) => {
    try {
      const usuarioAgregado = await httpRequest({
        url: `${endpointsPaths.usuarios}/${httpRequestsPaths.usuarios.registro.addUsuarioCortesia}`,
        method: "POST",
        body: JSON.stringify(datosUsuario),
      });

      resolve(usuarioAgregado);
    } catch (error) {
      // console.log("Error al registrar al usuario de lectura veloz: ", error);

      reject(error);
    }
  });
};
