import multiViewComponentsPaths from "@helpers/multiViewComponentsPaths/multiViewComponentsPaths";
import typesTiposUsuario from "@helpers/types/typesTiposUsuario";
import React from "react";
import { Col, Image, OverlayTrigger, Popover, Tooltip } from "react-bootstrap";

const AtajosUsuarioEstudiante = ({ handleChangeComponent, handleUltimaLeccion, ultimaLeccion, rol }) => {
	return (
		<>
			<Col className="mis-avances">
				<div className="lordicon-container">
					<OverlayTrigger placement="top" overlay={<Popover id={`popover-positioned-avances`}>
						<Popover.Header as="h3">Mis avances</Popover.Header>
						<Popover.Body>
							Mira el progreso de tu aprendizaje, tu tiempo en plataforma y tu última lección vista
						</Popover.Body>
					</Popover>}>
						<lord-icon
							onClick={() => handleChangeComponent("misAvances")}
							src="https://cdn.lordicon.com/oegrrprk.json"
							trigger="hover"
							colors="primary:#121331,secondary:#f59e0b,tertiary:#646e78,quaternary:#121331"
							style={{ width: "100%", height: "100%", cursor: "pointer" }}></lord-icon>
					</OverlayTrigger>
				</div>

				<h6 className="shadow home-shortcut-title">
					<b>Mis Avances</b>
				</h6>
			</Col>

			{ultimaLeccion !== null && (
				<Col className="last-action">
					<div className="lordicon-container">
						<OverlayTrigger placement="top" overlay={<Popover id={`popover-positioned-ultima-leccion`}>
							<Popover.Header as="h3">Continúa donde te quedaste</Popover.Header>
							<Popover.Body>
								Vuelve a tu aprendizaje desde tu última lección vista
							</Popover.Body>
						</Popover>}>
							<lord-icon
								onClick={() => handleUltimaLeccion(ultimaLeccion)}
								src="https://cdn.lordicon.com/fihkmkwt.json"
								style={{ width: "100%", height: "100%", cursor: "pointer" }}
								trigger="hover"
								colors="primary:#f59e0b,secondary:#f59e0b"></lord-icon>
						</OverlayTrigger>
					</div>
					<h6 className="shadow home-shortcut-title">
						<b>Continúa donde te quedaste</b>
					</h6>
				</Col>
			)}

			{rol !== typesTiposUsuario.gestion ?(
				<Col className="examenes-diagnostico">
				<div className="lordicon-container">
					<OverlayTrigger placement="top" overlay={<Popover id={`popover-positioned-examen`}>
						<Popover.Header as="h3">Examen diagnóstico</Popover.Header>
						<Popover.Body>
							Prueba tu conocimiento en Excel y Power BI y obtén un diagnóstico para saber que temas reforzar antes de tomar tus cursos
						</Popover.Body>
					</Popover>}>
						<lord-icon
							onClick={() => handleChangeComponent("examenDiagnostico")}
							src="https://cdn.lordicon.com/dxoycpzg.json"
							trigger="morph"
							colors="primary:#f59e0b,secondary:#646e78,tertiary:#121331,quaternary:#ebe6ef,quinary:#f59e0b"
							style={{ width: "100%", height: "100%", cursor: "pointer" }}></lord-icon>
					</OverlayTrigger>
				</div>

				<h6 className="shadow home-shortcut-title">
					<b>Examenes Diagnóstico</b>
				</h6>
			</Col>
			):null}

			{rol !== typesTiposUsuario.gestion ? (
			<Col className="rutas-aprendizaje">
				<div className="lordicon-container">
					<OverlayTrigger placement="top" overlay={<Popover id={`popover-positioned-rutas`}>
						<Popover.Header as="h3">Rutas de aprendizaje</Popover.Header>
						<Popover.Body>
							Crea listas de reproducción personalizadas con temas de nuestro catálogo de cursos que sea de tu interés
						</Popover.Body>
					</Popover>}>
						<lord-icon
							onClick={() => {
								handleChangeComponent("rutasAprendizaje");
							}}
							src="https://cdn.lordicon.com/shiwicyv.json"
							trigger="morph"
							colors="primary:#00152a,secondary:#f59e0b,tertiary:#ebe6ef"
							style={{ width: "100%", height: "100%", cursor: "pointer" }}></lord-icon>
					</OverlayTrigger>
				</div>
				<h6 className="shadow home-shortcut-title">
					<b>Rutas De Aprendizaje</b>
				</h6>
			</Col>
				
			): null}

			{rol !== typesTiposUsuario.gestion ? (
				<Col className="atajo-agendas-asesorias">
					<div className="lordicon-container">
						<OverlayTrigger placement="top" overlay={<Popover id={`popover-positioned-asesorias`}>
							<Popover.Header as="h3">Descargar Click+ Móvil</Popover.Header>
							<Popover.Body>
								Tu plataforma ahora va contigo a todas partes, descarga nuestra app móvil sin costo adicional y gestiona tu progreso, solicita tu estado de cuenta y disfruta de lecciones optimizadas para que puedas disfrutarlas donde quiera que vayas.
								<p><b>Escanea el QR para descargar la app.</b></p>
							</Popover.Body>
						</Popover>}>
							<Image src={require('@assets/images/qrClickplus.png')} width={"70%"} height={"90%"}/>
						</OverlayTrigger>
					</div>
					<h6 className="shadow home-shortcut-title">
						<b>Descargar Click+ Móvil</b>
					</h6>
				</Col>
			): null}
						



			<Col className="atajo-juegos">
				<div className="lordicon-container">
					<OverlayTrigger placement="top" overlay={<Popover id={`popover-positioned-entretenimiento`}>
						<Popover.Header as="h3">Sala de entretenimiento</Popover.Header>
						<Popover.Body>
							Entra a la sala de entretenimiento y relájate un momento antes de continuar con tu aprendizaje
						</Popover.Body>
					</Popover>}>
						<lord-icon
							onClick={() => {
								handleChangeComponent("sala-entretenimiento");
							}}
							src="https://cdn.lordicon.com/fjcesatc.json"
							trigger="morph"
							style={{ width: "100%", height: "100%", cursor: "pointer" }}></lord-icon>
					</OverlayTrigger>
				</div>
				<h6 className="shadow home-shortcut-title">
					<b>Sala de entretenimiento</b>
				</h6>
			</Col>

			{rol !== typesTiposUsuario.gestion ? (
				<Col className="atajo-agendas-asesorias">
				<div className="lordicon-container">
					<OverlayTrigger placement="top" overlay={<Popover id={`popover-positioned-asesorias`}>
						<Popover.Header as="h3">Agenda de asesorías</Popover.Header>
						<Popover.Body>
							¿Necesitas ayuda en temas de paquetería Office? Agenda una asesoría con uno de nuestros especialistas y con gusto te apoyaremos
						</Popover.Body>
					</Popover>}>
						<lord-icon
							onClick={() => {
								handleChangeComponent(multiViewComponentsPaths.agendasAsesorias);
							}}
							// src="https://cdn.lordicon.com/ibanawal.json"
							src="https://cdn.lordicon.com/bfnxlrjx.json"
							trigger="morph"
							style={{ width: "100%", height: "100%", cursor: "pointer" }}></lord-icon>
					</OverlayTrigger>
				</div>
				<h6 className="shadow home-shortcut-title">
					<b>Agenda de Asesorías</b>
				</h6>
			</Col>
			): null}

			<Col className="atajo-pomodoro">
				<div className="lordicon-container">
					<OverlayTrigger placement="top" overlay={<Popover id={`popover-positioned-pomodoro`}>
						<Popover.Header as="h3">Técnica Pomodoro</Popover.Header>
						<Popover.Body>
							Maximiza tu concentración con esta técnica de gestión de tiempo que te ayudará en la organización del tiempo que le dediques a tu aprendizaje
						</Popover.Body>
					</Popover>}>
						<lord-icon
							onClick={() => {
								handleChangeComponent("pomodoro");
							}}
							src="https://cdn.lordicon.com/gpjvveei.json"
							trigger="morph"
							style={{ width: "100%", height: "100%", cursor: "pointer" }}></lord-icon>
					</OverlayTrigger>
				</div>
				<h6 className="shadow home-shortcut-title">
					<b>Técnica Pomodoro</b>
				</h6>
			</Col>
		
			{rol === "administrador" && (
				<Col className="panel-administrador">
					<div className="lordicon-container">
						<OverlayTrigger placement="top" overlay={<Popover id={`popover-positioned-administracion`}>
							<Popover.Header as="h3">Panel de administración</Popover.Header>
							<Popover.Body>
								Gestiona el contenido de la plataforma
							</Popover.Body>
						</Popover>}>
							<Image className="pointer" onClick={() => {
								handleChangeComponent("panelAdministracion");
							}} src={require("@assets/images/engranaje.png")} width={"85%"} height={"100%"} />
						</OverlayTrigger>
					</div>
					<h6 className="shadow home-shortcut-title">
						<b>Panel de administración</b>
					</h6>
				</Col>
			)}
		</>
	);
};

export default AtajosUsuarioEstudiante;
