import { ThemeProvider } from "@emotion/react";
import Loader from "@helpers/loader/loader";

import { Avatar, CssBaseline, FormControl, FormControlLabel, FormLabel, List, ListItem, ListItemAvatar, ListItemText, Radio, RadioGroup, Typography, createTheme } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Animated } from "react-animated-css";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { Cronometro } from "./components/Cronometro";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import Ripple from "./components/Ripple";
import RippleButton from "./components/RippleButton";

import "./components/cronometro.css";
import TitleDashboard from "@components/general/Titles/TitleDashboard";
import { laCasaDeChocolate } from "./utils/laCasaDeChocolate";
import { laRosaMasBellaDelMundo } from "./utils/laRosaMasBellaDelMundo";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { NotificationManager } from "react-notifications";

import MenuBookIcon from "@mui/icons-material/MenuBook";
import { Assignment, Insights, Pin, Timer } from "@mui/icons-material";
import { isMobile } from "@helpers/validaciones/isMobile";

const preguntasCasaDeChocolate = [
	{
		id: "pregunta1",
		pregunta: "¿Cómo se llamaban los protagonistas de la historia?",
		opciones: ["Miguel y Perla", "Hansel y Gretel", "Felipe y Morita", "Anacleto y Cenicienta"],
		respuesta: "Hansel y Gretel",
	},
	{
		id: "pregunta2",
		pregunta: "¿Qué hacían los hermanos en el bosque mientras recogían ramas? ¿Quiénes habitaban en el bosque de robles?",
		opciones: ["Jugaban con los animales", "Peleaban por las ramas de cada uno", "Comían manzanas verdes", "Cantaban y saltaban"],
		respuesta: "Cantaban y saltaban",
	},
	{
		id: "pregunta3",
		pregunta: "¿Qué encontraron en medio del bosque?",
		opciones: ["Comida", "Una bruja", "Una casa de chocolate", "Al papá"],
		respuesta: "Una casa de chocolate",
	},
	{
		id: "pregunta4",
		pregunta: "¿Qué quería hacer la bruja con los hermanos?",
		opciones: ["Que trabajaran para ella", "Hacerles un hechizo para engordaran", "Comerlos", "Venderlos a otra bruja"],
		respuesta: "Comerlos",
	},
	{
		id: "pregunta5",
		pregunta: "¿Cómo llegaron los hermanos a la casa de su padre?",
		opciones: ["Corriendo", "En el coche de la bruja", "Con la ayuda de los animales del bosque", "Montados en la escoba voladora de la bruja"],
		respuesta: "Montados en la escoba voladora de la bruja",
	},
];

const preguntasRosaMasBellaDelMundo = [
	{
		id: "pregunta1",
		pregunta: "¿Qué flor tenía la reina como su preferida en el palacio?",
		opciones: ["Gardenias", "Rosas", "Petunias", "Violetas"],
		respuesta: "Rosas",
	},
	{
		id: "pregunta2",
		pregunta: "¿Por qué en el palacio había tristeza?",
		opciones: ["La reina estaba enferma y se iba a morir", "Los reyes ya no se amaban más", "Las rosas no volvieron a crecer", "La reina se fue del palacio"],
		respuesta: "La reina estaba enferma y se iba a morir",
	},
	{
		id: "pregunta3",
		pregunta: "¿Cómo se podía salvar a la reina?",
		opciones: [
			"Mostrándole la rosa que fuera la expresión del amor puro y más sublime",
			"Llevándola al jardín más hermoso y puro del país",
			"Con la prueba del amor verdadero",
			"Con la ayuda de los sabios del reino",
		],
		respuesta: "Mostrándole la rosa que fuera la expresión del amor puro y más sublime",
	},
	{
		id: "pregunta4",
		pregunta: "¿Quién le mostró a la reina la rosa más bella para su sanidad?",
		opciones: [
			"El poeta, con sus canciones",
			"La madre, con su bebé que recién se despertaba",
			"Su hijo, leyendo el pasaje del sacrificio de Cristo en la cruz por amor a la humanidad",
			"El obispo, hablando sobre el pacto de alianza en el bautismo",
		],
		respuesta: "Su hijo, leyendo el pasaje del sacrificio de Cristo en la cruz por amor a la humanidad",
	},
	{
		id: "pregunta5",
		pregunta: "¿Qué sucedió con la reina después?",
		opciones: [
			"Se sanó",
			"Quedó viva, pero postrada en la cama",
			"Murió feliz",
			"Hizo fiesta en el reino por su sanidad                                                                                                       ",
		],
		respuesta: "Se sanó",
	},
];

const RenderTime = ({ remainingTime }) => {
	const currentTime = useRef(remainingTime);

	const prevTime = useRef(null);

	const isNewTimeFirstTick = useRef(false);

	const [, setOneLastRerender] = useState(0);

	if (currentTime.current !== remainingTime) {
		isNewTimeFirstTick.current = true;
		prevTime.current = currentTime.current;
		currentTime.current = remainingTime;
	} else {
		isNewTimeFirstTick.current = false;
	}

	// force one last re-render when the time is over to tirgger the last animation
	if (remainingTime === 0) {
		setTimeout(() => {
			setOneLastRerender((val) => val + 1);
		}, 20);
	}

	const isTimeUp = isNewTimeFirstTick.current;

	return (
		<div className="time-wrapper">
			<div key={remainingTime} className={`time ${isTimeUp ? "up" : ""}`}>
				{remainingTime}
			</div>
			{prevTime.current !== null && (
				<div key={prevTime.current} className={`time ${!isTimeUp ? "down" : ""}`}>
					{prevTime.current}
				</div>
			)}
		</div>
	);
};

const validarCamposVacios = (objeto) => {
	for (let clave in objeto) {
		if (!objeto[clave]) {
			return true; // Si encuentra una propiedad vacía, retorna true
		}
	}
	return false; // Si no encuentra ninguna propiedad vacía, retorna false
};

const evaluarFormulario = (respuestas, preguntas) => {
	try {
		const maximoAciertos = preguntas.length;

		let numeroAciertos = 0;

		for (const respuesta in respuestas) {
			if (Object.hasOwnProperty.call(respuestas, respuesta)) {
				const respuestaUsuario = respuestas[respuesta];

				const pregunta = preguntas.find((pregunta) => pregunta.id === respuesta);

				if (pregunta.respuesta === respuestaUsuario) {
					numeroAciertos++;
				}
			}
		}

		return Math.round((numeroAciertos * 100) / maximoAciertos);
	} catch (error) {
		console.log("error al evaluar el formulario: ", error);
	}
};

const convertirSegundosAHoras = (segundos) => {
	const horas = Math.floor(segundos / 3600);
	const minutos = Math.floor((segundos % 3600) / 60);
	const segundosRestantes = segundos % 60;

	const horasFormateadas = String(horas).padStart(2, "0");
	const minutosFormateados = String(minutos).padStart(2, "0");
	const segundosFormateados = String(segundosRestantes).padStart(2, "0");

	return `${horasFormateadas}:${minutosFormateados}:${segundosFormateados}`;
};

const ExamenDiagnosticoLecturaVeloz = () => {
	const urlParams = new URLSearchParams(useLocation().search);

	const lightTheme = createTheme({
		palette: {
			mode: "light",
		},
	});

	const [showLoader, setShowLoader] = useState(false);

	const [mostrarCronometro, setMostrarCronometro] = useState(false);

	const [comenzarCountdown, setComenzarCountdown] = useState(false);

	const [mostrarFormulario, setMostrarFormulario] = useState(false);

	const [mostrarResultadosEvaluacion, setMostrarResultadosEvaluacion] = useState(false);

	const [tiempoTranscurrido, setTiempoTranscurrido] = useState(0);

	const [numeroPalabras, setNumeroPalabras] = useState(0);

	const [tiempoUsado, setTiempoUsado] = useState(0);

	const [palabrasPorMinuto, setPalabrasPorMinuto] = useState(0);

	const [resultadoEvaluacion, setResultadoEvaluacion] = useState(0);

	const [fechaInicio, setFechaInicio] = useState(new Date());

	const [formValues, setFormValues] = useState({
		pregunta1: "",
		pregunta2: "",
		pregunta3: "",
		pregunta4: "",
		pregunta5: "",
	});

	const [texto, setTexto] = useState("");

	const [tituloTexto, setTituloTexto] = useState("");

	const [preguntas, setPreguntas] = useState([]);

	const obtenerTiempoTranscurrido = (tiempo) => {
		setTiempoTranscurrido(tiempo);
	};

	const handlePlayCronometro = () => {
		setComenzarCountdown(true);
	};

	const handleSeleccionRespuesta = ({ target }) => {
		const value = target.value;
		const name = target.name;

		setFormValues({
			...formValues,
			[name]: value,
		});
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		try {
			const camposVacios = validarCamposVacios(formValues);

			if (camposVacios) {
				return NotificationManager.error("Responde todas las preguntas para continuar");
			}

			const cantidadPalabras = texto.trim().split(/\s+/).length;

			const palabrasPorMinuto = Math.round((cantidadPalabras * 60) / tiempoTranscurrido);

			const resultadoEvaluacion = evaluarFormulario(formValues, preguntas);

			setNumeroPalabras(cantidadPalabras);

			setPalabrasPorMinuto(palabrasPorMinuto);

			setTiempoUsado(convertirSegundosAHoras(tiempoTranscurrido));

			setResultadoEvaluacion(resultadoEvaluacion);

			setMostrarResultadosEvaluacion(true);
		} catch (error) {
			console.log("error al calcular las palabras por minuto y evaluar el formulario: ", error);
		}
	};

	useEffect(() => {
		const lectura = urlParams.get("lectura");

		if (lectura === "casa-chocolate") {
			setTexto(laCasaDeChocolate);

			setTituloTexto("La casa de Chocolate");

			setPreguntas(preguntasCasaDeChocolate);
		} else if (lectura === "rosa-mas-bella-mundo") {
			setTexto(laRosaMasBellaDelMundo);

			setTituloTexto("La rosa más bella del mundo");

			setPreguntas(preguntasRosaMasBellaDelMundo);
		}

		setTimeout(() => {
			setShowLoader(false);
		}, 1500);
	}, []);

	return (
		<>
			{showLoader ? (
				<Loader />
			) : (
				<>
					<ThemeProvider theme={lightTheme}>
						<CssBaseline />

						<Animated animationIn="fadeIn" isVisible={true}>
							<div id="particles" className="container-form-registro" style={ !mostrarCronometro ? {height: '100%'} : { }}>
								<Container className={`py-4 my-3 ${mostrarFormulario || mostrarCronometro ? "" : "vh-100"}`}>
									{/* <Typography color="#08759e" variant="h3" gutterBottom className="text-justify text-uppercase text-center">
										Test de velocidad de lectura
									</Typography>

									<Typography color="#08759e" variant="h5" gutterBottom className="text-justify text-uppercase text-center">
										Pon a prueba tus habilidades de lectura veloz con este pequeño examen de evaluación
									</Typography> */}

									<Typography variant="h6" gutterBottom className="text-justify text-uppercase text-center">
										{tituloTexto}
									</Typography>

									{mostrarFormulario ? (
										<Animated animationIn="fadeIn" isVisible={true}>
											<Card style={{border: 'none'}} body className={`mt-3 align mb-5 ${ isMobile.any() ? 'w-auto' : 'w-100' }`}>
												{mostrarResultadosEvaluacion ? (
													<>
														<TitleDashboard title="Resultados de la evaluación" />

														<Container>
															<Row>
																<Col>
																	<List>
																		<ListItem>
																			<ListItemAvatar>
																				<Avatar>
																					<MenuBookIcon />
																				</Avatar>
																			</ListItemAvatar>
																			<ListItemText primary="Título de la lectura" secondary={tituloTexto} />
																		</ListItem>

																		<ListItem>
																			<ListItemAvatar>
																				<Avatar>
																					<Pin />
																				</Avatar>
																			</ListItemAvatar>
																			<ListItemText primary="Número de palabras" secondary={`${numeroPalabras} palabras`} />
																		</ListItem>

																		<ListItem>
																			<ListItemAvatar>
																				<Avatar>
																					<Timer />
																				</Avatar>
																			</ListItemAvatar>
																			<ListItemText primary="Tiempo usado" secondary={tiempoUsado} />
																		</ListItem>
																	</List>
																</Col>

																<Col>
																	<List>
																		<ListItem>
																			<ListItemAvatar>
																				<Avatar>
																					<Insights />
																				</Avatar>
																			</ListItemAvatar>
																			<ListItemText primary="Velocidad lectora" secondary={`${palabrasPorMinuto} palabras/minuto`} />
																		</ListItem>

																		<ListItem>
																			<ListItemAvatar>
																				<Avatar>
																					<Assignment />
																				</Avatar>
																			</ListItemAvatar>
																			<ListItemText primary="Comprensión lectora" secondary={`${resultadoEvaluacion} % de aciertos`} />
																		</ListItem>
																	</List>
																</Col>
															</Row>
														</Container>
													</>
												) : (
													<>
														<TitleDashboard title="Contesta las siguientes preguntas" />

														<Container>
															<Form className="mt-3" onSubmit={handleSubmit}>
																<Row className="d-flex justify-content-center">
																	{preguntas.map((pregunta, idx) => (
																		<Col key={idx} xs={12} className="mb-3">
																			<FormControl>
																				<FormLabel id={`id_${idx}`}>{pregunta.pregunta}</FormLabel>

																				<RadioGroup aria-labelledby={`id_${idx}`} name={pregunta.id} onChange={handleSeleccionRespuesta}>
																					{pregunta.opciones.map((opcion, idxOpt) => (
																						<FormControlLabel key={idxOpt} value={opcion} control={<Radio />} label={opcion} />
																					))}
																				</RadioGroup>
																			</FormControl>
																		</Col>
																	))}
																</Row>

																<Row>
																	<Col>
																		<div className="d-grid gap-2">
																			<Button variant="primary" type="submit">
																				Enviar
																			</Button>
																		</div>
																	</Col>
																</Row>
															</Form>
														</Container>
													</>
												)}
											</Card>
										</Animated>
									) : (
										<>
											<Row className="mt-3">
												<Col align="right">
													{
														mostrarCronometro ? (
															<Cronometro inicio={fechaInicio} obtenerTiempoTranscurrido={obtenerTiempoTranscurrido} />
														) : (
															// <Typography variant="h5" gutterBottom>
															// 	Da clic en el botón para comenzar
															// </Typography>
															null
														)
													}
												</Col>
											</Row>

											<Row className={`d-flex justify-content-center align-content-center mb-5 ${mostrarCronometro ? "" : "h-75"}`}>
												<Col>
													{mostrarCronometro ? (
														<Row>
															<Col>
																<Animated animationIn="fadeIn" isVisible={true}>
																	<Card body>
																		{texto.split("\n").map((linea, index) => (
																			<Typography key={index} variant="body1" gutterBottom className="text-justify mb-3">
																				{linea}
																			</Typography>
																		))}

																		<div className="d-flex justify-content-center">
																			<Button
																				onClick={() => {
																					console.log("finalizando cronometro");

																					// setFinalizarCronometro(true);

																					setMostrarFormulario(true);
																				}}
																				variant="danger">
																				Finalizar
																			</Button>
																		</div>
																	</Card>
																</Animated>
															</Col>
														</Row>
													) : (
														<Animated animationIn="fadeIn" isVisible={true}>
															<Row className="d-flex justify-content-center">
																{comenzarCountdown ? (
																	<div className="timer-wrapper">
																		<CountdownCircleTimer
																			isPlaying
																			duration={3}
																			colors={["#dc3545", "#F7B801", "#198754", "#198754"]}
																			colorsTime={[3, 2, 1, 0]}
																			onComplete={() => {
																				setFechaInicio(new Date());

																				setMostrarCronometro(true);

																				setComenzarCountdown(false);
																			}}>
																			{RenderTime}
																		</CountdownCircleTimer>
																	</div>
																) : (
																	<RippleButton onClick={handlePlayCronometro}>
																		<FontAwesomeIcon icon={faPlay} size="4x" color="#fd5900" />

																		<Ripple color={"#08759e"} duration={1500} />
																	</RippleButton>
																)}
															</Row>
														</Animated>
													)}
												</Col>
											</Row>
										</>
									)}
								</Container>
							</div>
						</Animated>
					</ThemeProvider>
				</>
			)}
		</>
	);
};

export default ExamenDiagnosticoLecturaVeloz;
