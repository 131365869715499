import { Button, Col, Container, Image, Nav, Navbar, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { IoIosClose, IoIosMenu } from "react-icons/io";
import React, { useContext, useEffect, useState } from "react";

import BarraBusqueda from "./BarraBusqueda/BarraBusqueda";
import DatosUsuario from "./DatosUsuario/DatosUsuario";
import UserContext from "@context/User/UserContext";
import { useHistory } from "react-router-dom";
import useWindowResize from "customHooks/useWindowResize";
import multiViewComponentsPaths from "@helpers/multiViewComponentsPaths/multiViewComponentsPaths";
import typesTiposUsuario from "@helpers/types/typesTiposUsuario";
import { getTemas } from "@helpers/httpRequests/httpRequestApariencia/httpRequestApariencia";

const NavBar = ({ className, toggleSidebar, isSidebarActive, setIsSidebarActive, handleChangeComponent, temaSeleccionado, setTemaSeleccionado }) => {
	const history = useHistory();

	const {
		size: { width },
	} = useWindowResize();

	const { contextValue: userContextValue, setContextValue: setUserContextValue } = useContext(UserContext);

	// const { contextValue: multiViewContextValue, setContextValue: setMultiViewContextValue } = useContext(MultiViewContext);

	const { usuarioLogeado } = userContextValue;

	const { nombreCompleto, apellidos, servicioActivo, rol} = usuarioLogeado;

	const [menuColapse, setMenuColapse] = useState(false);

	// const [temas, setTemas] = useState(["", ""]);

	const [logoSeleccionado, setLogoSeleccionado] = useState("click.png");

	const [nombreLogoSeleccionado, setNombreLogoSeleccionado] = useState("Sin tema")

	// const [barraSeleccionada, setBarraSeleccionada] = useState("");

	const [datosUsuario, setDatosUsuario] = useState({
		nombreUsuario: `${nombreCompleto} ${apellidos}`,
		permiso: false,
	});

	const handleChangeSideBar = (e) => {
		setIsSidebarActive(!isSidebarActive);
	};

	const handleProfile = (x) => {
		const { miPerfil } = multiViewComponentsPaths;

		try {
			handleChangeComponent(miPerfil);
		} catch (e) {
			console.log("handleProfile");

			console.log(e);
		}
	};

	const handleProfileSelection = () => {
		sessionStorage.removeItem("lastRoute");

		history.push("/perfiles");
	};

	const handleBotonColapse = () => {
		try {
			if (isSidebarActive === false) {
				setMenuColapse(!menuColapse); //desaparece el boton

				handleChangeSideBar(); //muestra el menu activo largo
			} else {
				handleChangeSideBar(); //muestra el menu corto

				setMenuColapse(false); //mantener el boton invisible
			}
		} catch (e) {
			console.log(e);
		}
	};

	const handleRegisterSecondaryUser = async () => {
		try {
			handleChangeComponent("registroSegundoUsuario");
			// setState({ direccion: 'segundoPerfil' });
		} catch (e) {
			console.log(e);
		}
	};

	const handleLogout = () => {
		try {
			localStorage.removeItem("dataUserLogged");

			localStorage.removeItem("lastRoute");

			localStorage.removeItem("token");

			localStorage.clear();

			sessionStorage.clear();

			// userContext.setStateContext({});

			history.replace("/");
		} catch (e) {
			console.log(e);
		}
	};

	const [temas, setTemas] = useState(null);

	useEffect(() => {
		try {
			// handlePermition();
			const obtenerTemasApariencia = async () => {
				try {
					const { data } = await getTemas();
					data.map((tema) => {
						if (tema.estatus) {
							setNombreLogoSeleccionado(tema.nombreTema);
							setLogoSeleccionado(tema.aparienciaLogo);
						}
					});
					setTemas(data);
				} catch (error) {
					console.log(error);
				}
			};

			obtenerTemasApariencia();
		} catch (e) {
			console.log(e);
		}
	}, []);

	useEffect(() => {
		try {
			// handlePermition();
			const obtenerTemasApariencia = async () => {
				try {
					const { data } = await getTemas();
					data.map((tema) => {
						if (tema.estatus) {
							setNombreLogoSeleccionado(tema.nombreTema);
							setLogoSeleccionado(tema.aparienciaLogo);
						}
					});
					setTemas(data);

					setTemaSeleccionado(false);
				} catch (error) {
					console.log(error);
				}
			};

			obtenerTemasApariencia();
		} catch (e) {
			console.log(e);
		}
	}, [temaSeleccionado]);

	const handleSidebarCollapse = (e) => {
		// console.log('first')

		if (width > 768) {
			handleBotonColapse();
		} else {
			toggleSidebar();
		}
	};

	const BotonMenuSidebar = () => {
		return (
			<OverlayTrigger placement="bottom" overlay={<Tooltip id={`tooltip-top`}>Desplegar menú</Tooltip>}>
				<Button variant="outline-warning" onClick={handleSidebarCollapse} className="navbar-buttons">
					{isSidebarActive ? <IoIosClose /> : <IoIosMenu />}
				</Button>
			</OverlayTrigger>
		);
	};

	return (
		<>
			{/* Bootstrap 5 */}
			<Navbar expand="lg" data-bs-theme="dark" bg="dark" className="bg-dark-custom d-flex justify-content-between">
				<div className="d-flex align-items-center">
					<Navbar.Brand href="#home">
						<div className="m-1">
							<img
								className="pointer"
								onClick={() => handleChangeComponent(rol !== 'lectura_veloz' ? "home" : "homeLecturaVeloz")}
								src={nombreLogoSeleccionado !== "Personalizado" ? require(`@assets/images/${logoSeleccionado}`) : logoSeleccionado}
								alt="Logo Global Click"
								style={{ width: `${width > 992 ? "10vw" : "20vw"}` }}
							/>
						</div>
					</Navbar.Brand>

					<OverlayTrigger placement="right" overlay={<Tooltip id={`tooltip-top`}>Desplegar menú</Tooltip>}>
						<Button variant="outline-warning" onClick={handleSidebarCollapse} className="navbar-buttons">
							{isSidebarActive ? <IoIosClose /> : <IoIosMenu />}
						</Button>
					</OverlayTrigger>
				</div>

				{
					servicioActivo ? 
					(usuarioLogeado.rol !== typesTiposUsuario.invitado && usuarioLogeado.rol !== typesTiposUsuario.lecturaVeloz && usuarioLogeado.rol !== typesTiposUsuario.cortesia  && usuarioLogeado.rol !== typesTiposUsuario.gestion) && (
						<span className={`search-bar ms-3 ${width < 1024 ? "d-none" : ""}`}>
							<BarraBusqueda handleChangeComponent={handleChangeComponent} parent="desktop-navbar" width={width} handleSidebarCollapse={handleSidebarCollapse} isSidebarActive={isSidebarActive} />
						</span>
					)
					: null
				}

				<DatosUsuario
					nombreUsuario={datosUsuario.nombreUsuario}
					handleChangeComponent={handleChangeComponent}
					handleProfile={handleProfile}
					handleProfileSelection={handleProfileSelection}
					handleRegisterSecondaryUser={handleRegisterSecondaryUser}
					handleLogout={handleLogout}
					width={width}
				/>
			</Navbar>

			{/* Bootstrap 4.6 */}

			{/* <header role="banner" className={`navbar navbar-expand navbar-light bg-dark-custom ${className}`}>
				<div className="m-1">
					<img
						className="pointer"
						onClick={() => handleChangeComponent("home")}
						src={require(`@assets/images/${logoSeleccionado}`)}
						alt="Logo Global Click"
						style={{ width: `${width > 992 ? "10vw" : "20vw"}` }}
					/>
				</div>

				<OverlayTrigger placement="bottom" overlay={<Tooltip id={`tooltip-top`}>Desplegar menú</Tooltip>}>
					<Button variant="outline-warning" onClick={handleSidebarCollapse} className="navbar-buttons">
						{isSidebarActive ? <IoIosClose /> : <IoIosMenu />}
					</Button>
				</OverlayTrigger>

				{usuarioLogeado.rol !== typesTiposUsuario.invitado && usuarioLogeado.rol !== typesTiposUsuario.lecturaVeloz && (
					<span className={`search-bar ms-3 ${width < 768 ? "d-none" : ""}`}>
						<BarraBusqueda handleChangeComponent={handleChangeComponent} parent="desktop-navbar" width={width} />
					</span>
				)}

				<DatosUsuario
					nombreUsuario={datosUsuario.nombreUsuario}
					handleChangeComponent={handleChangeComponent}
					handleProfile={handleProfile}
					handleProfileSelection={handleProfileSelection}
					handleRegisterSecondaryUser={handleRegisterSecondaryUser}
					handleLogout={handleLogout}
					width={width}
				/>
			</header> */}
		</>
	);
};

export default NavBar;
